import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Drawer } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Map from "./Map";

const MapDrawer = ({
  isEditable,
  isOpen,
  setIsOpen,
  address,
  useLatLng,
  btnName,
  btnClickHandler,
  hideArrow,
}) => {
  const [currentLatLng, setCurrentLatLng] = useState({});

  const {
    building,
    area,
    landmark,
    district,
    state,
    country,
    pincode,
    latitude,
    longitude,
  } = address;
  const addressString = `${building}, ${area}, ${landmark}, ${district}, ${state}, ${country}, ${pincode}`;
  const initialLatLng = { lat: latitude, lng: longitude };
  const title = isEditable ? "Drag & confirm your location" : "Location";

  const handleCurrentLatLng = (latLng) => {
    setCurrentLatLng(latLng);
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="16px"
        width="100%"
        zIndex="tooltip"
        bgcolor="#ffffff"
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => setIsOpen(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Map
        isEditable={isEditable}
        addressString={addressString}
        initialLatLng={initialLatLng}
        useInitialLatLng={useLatLng}
        handleLatLng={handleCurrentLatLng}
      />
      <Button
        size="large"
        variant="contained"
        onClick={() => btnClickHandler(currentLatLng)}
        endIcon={!hideArrow && <ArrowForwardIcon />}
      >
        {btnName}
      </Button>
    </Drawer>
  );
};

export default MapDrawer;
