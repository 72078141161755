import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Button } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

import CardTitleWithButton from "../../CardTitleWithButton";
import LabelValueText from "../../LabelValueText";
import MapDrawer from "../../MapDrawer";

const AddressDisplay = ({ isEditable, address }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Card style={{ margin: "10px 0px" }}>
        <CardContent>
          <CardTitleWithButton
            title={"Address"}
            btnName={isEditable && "Edit"}
            btnClickHandler={() => navigate("/kitchen-registration/address")}
          />
          <LabelValueText label="Building" value={address.building} />
          <LabelValueText label="Area" value={address.area} />
          {address.landmark && (
            <LabelValueText label="Landmark" value={address.landmark} />
          )}
          <LabelValueText label="District" value={address.district} />
          <LabelValueText label="State" value={address.state} />
          <LabelValueText label="Country" value={address.country} />
          <LabelValueText label="Pincode" value={address.pincode} />
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => setIsOpen(true)}
            endIcon={<ArrowUpward />}
            sx={{ marginTop: "10px" }}
          >
            View location on Map
          </Button>
        </CardContent>
      </Card>
      <MapDrawer
        isEditable={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        address={address}
        useLatLng={true}
        btnName="Close"
        btnClickHandler={() => setIsOpen(false)}
        hideArrow
      />
    </>
  );
};

export default AddressDisplay;
