import React, { useState, useEffect } from "react";

const Map = ({
  isEditable,
  addressString,
  initialLatLng,
  useInitialLatLng,
  handleLatLng,
}) => {
  const [marker, setMarker] = useState(null);
  const [internalLatLng, setInternalLatLng] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    handleLatLng(internalLatLng);
  }, [internalLatLng]);

  useEffect(() => {
    if (!window.google) {
      console.error("Google Maps API not loaded");
      return;
    }

    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 15,
      center: { lat: 0, lng: 0 },
    });
    const geocoder = new window.google.maps.Geocoder();
    const params = {};
    if (useInitialLatLng) {
      params.location = initialLatLng;
    } else {
      params.address = addressString;
    }
    geocoder.geocode(params, (results, status) => {
      if (status === "OK") {
        const initialLocation = results[0].geometry.location;
        map.setCenter(initialLocation);
        const newMarker = new window.google.maps.Marker({
          position: initialLocation,
          map,
          draggable: isEditable,
        });
        setMarker(newMarker);
        setInternalLatLng({
          lat: initialLocation.lat(),
          lng: initialLocation.lng(),
        });
      } else {
        console.error("Geocode was not successful: " + status);
      }
    });
  }, [addressString, isEditable]);

  const handleMarkerDrag = () => {
    const position = marker.getPosition();
    setInternalLatLng({ lat: position.lat(), lng: position.lng() });
  };

  useEffect(() => {
    if (marker) {
      marker.addListener("drag", handleMarkerDrag);
    }
  }, [marker]);

  if (!addressString && !initialLatLng) {
    return <div>Something went wrong</div>;
  }

  return (
    <div>
      <div id="map" style={{ height: "75vh", width: "100%" }}></div>
    </div>
  );
};

export default Map;
